import { createEffect, splitProps } from "solid-js";
import { InputViewProps, FormControlData, FormControlValue } from "../model";
import { createFormControl } from "solid-forms";

export function Input<V extends FormControlValue, D extends FormControlData>(props: InputViewProps<V, D>) {
  const [local, others] = splitProps(props, ["control", "onChange", "update"]);
  const _control = Array.isArray(local.control) ? createFormControl<V, D>(...local.control) : local.control;
  const control_is_array = Array.isArray(local.control);

  createEffect(() => {
    if (control_is_array && local.update) {
      console.log("in form input :: ");
      _control.setValue(local.control[0]);
    }
    // console.log()
  });
  return (
    <input
      {...others}
      classList={{
        invalid: !!_control.errors,
        touched: _control.isTouched,
        disabled: _control.isDisabled,
        // required: control.isRequired,
      }}
      value={others.type !== "file" ? _control.value : ""}
      oninput={(e) => {
        const { type } = others; //e.currentTarget || {};
        let v = e.currentTarget.value as V;
        if (type === "radio") {
          v = e.currentTarget.id as V;
        } else if (type === "checkbox") {
          // v = e.currentTarget.id as V;
          // v = (!e.target.checked ? "false" : "true") as V;
          v = e.target.checked as unknown as V;
          // console.log("form ype is :: ", type, v);
        } else if (type === "file") {
          if (e.target.files && e.target.files?.[0]) {
            v = e.target.files?.[0] as any as V;
            // _control.setValue(content);
          }
        }

        // console.log("value form is :: ", v);
        _control.setValue(v);
        if (_control.isValid) {
          if (local.onChange) {
            local.onChange(v);
          }
        }
      }}
      onblur={() => _control.markTouched(true)}
      onfocus={() => _control.markTouched(true)}
      onfocusin={() => _control.markTouched(true)}
      onfocusout={() => _control.markTouched(false)}
      disabled={_control.isDisabled}
      required={_control.isRequired}
    />
  );
}
